.sourceDocContainer {
  background-color: #ffffff;
  margin: auto 0;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  z-index: 998;
  overflow: hidden;
}

.sourceDocInternalContainer {
  height: inherit;
  overflow-y: auto;
  padding: 3%;
}

.openSD {
  opacity: 1;
  transition-duration: 0.2s;
}

.SDMaximizeWrapper {
  position: fixed;
  top: 1.5%;
  left: 3.2%;
  height: 15px;
  width: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  cursor: pointer;
  border: 1px solid #ffaea6;
  border-radius: 25px;
}

.SDMaximize {
  position: relative;
  height: 50%;
  width: 28%;
  border: 1px solid #ffaea6;
  border-radius: 2px;
  z-index: 9999;
}

.SDMaximizeWrapper:hover .SDMaximize {
  background-color: white;
  height: 60%;
}

.SDMaximizeWrapper:hover {
  background-color: #ffaea6;
}

.SDMinimizeWrapper {
  position: relative;
  top: 1.5%;
  left: calc(3.2% + 38px);
  height: 15px;
  width: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  cursor: pointer;
  border: 1px solid #ffaea6;
  border-radius: 25px;
}

.SDMinimize {
  position: relative;
  height: 1px;
  width: 40%;
  background-color: #ffaea6;
  border-radius: 6px;
  z-index: 9999;
}

.SDMinimizeWrapper:hover .SDMinimize {
  background-color: white;
  height: 3px;
}

.SDMinimizeWrapper:hover {
  background-color: #ffaea6;
}

.SourceDocButtonWrapper {
  position: fixed;
  top: 43vh;
  right: -1vw;
  height: 7vw;
  width: 7vw;
  cursor: pointer;
}

.TabPanel {
  height: 100%;
}

.SDContentContainer {
  position: relative;
  margin-top: 2%;
  height: 87%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.SDContent {
  position: relative;
  width: 90%;
  height: 100%;
}

.MuiTabs-indicator {
  transform: translateX(
    11%
  ) !important; /* To fix the indicator position after scaling the app down */
}
