.codeContainer {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: "100%";
}

.largeGreyButtonWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  height: 20vh;
  margin-top: 2%;
}

.largeGreyButton {
  width: 100%;
  height: 95%;
  color: #7e7e7e;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: 1px solid lightgrey;
}

.largeGreyButton:hover {
  opacity: 0.9;
  border: 1px solid rgb(175, 175, 175);
  color: #6c6c6c;
  transition: cubic-bezier(0.075, 0.82, 0.165, 1);
  background-color: #fbfbfb;
  cursor: pointer;
}

.circularProgressContainer {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50%;
  padding-bottom: 80%;
  width: 100%;
  background: rgba(255, 255, 255);
  z-index: 9999;
}
.unlinkIconWrapper {
  text-align: left;
  padding: 0;
  margin: 0;
  margin-left: -5px;
  color: rgba(45, 45, 45, 0.943);
}

.unlinkIconWrapper:hover {
  color: black;
  cursor: pointer;
}
