.RecentFiles {
  margin-top: 20px;
  min-width: 400px;
  width: 30%;
}

.RecentFilesDropDownWrapper {
  color: white;
}

.RecentFilesDropdown {
  border-radius: 40px;
  padding: 6px 25px;
  outline: none;
  border: 1px solid #ffaea6;
  color: white;
  background: #ffaea6;
  appearance: none;
  cursor: pointer;
  font-size: 13px;
  font-family: Poppins-Medium;
}
