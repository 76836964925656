.SourceDocFile {
  position: relative;
  width: 90%;
  border-radius: 30px;
  margin: 5px 0;
  box-shadow: rgba(0, 0, 0, 0) 1.95px 1.95px 2.6px;
  display: flex;
  justify-content: space-between;
  max-height: 40px;
  color: #444444;
}

.SourceDocFileInternalWrapper {
  width: 100%;
  height: 100%;
  padding: 7px 15px;
}

.SourceDocFileInternalContent {
  position: relative;
  display: flex;
  align-items: center;
}

.SourceDocFileInternalText {
  font-family: "Poppins-Medium";
  font-size: 12px;
  color: "#25252";
  margin: 0;
  user-select: none;
}
.SourceDocFileInternalTextSub{
  font-family: "Poppins-Medium";
  font-size: 12px;
  color: rgb(154, 154, 154);
  margin-left: 10px;
}

.SourceDocFile:hover {
  box-shadow: rgba(0, 0, 0, 0.423) 1.95px 1.95px 2.6px;
  cursor: default;
}

.selectedFile {
  border: 1px solid #ff8b8b;
}

.iconWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  transition: ease-in 0.1s;
  margin-right: 10px;
  color: rgba(104, 104, 104, 0.943);
}

.linkedIconWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  transition: ease-in 0.1s;
  margin-right: 8px;
  color: rgba(104, 104, 104, 0.943);
}

.unlinked {
  background-color: #eeeeee;
}

.linked {
  background-color: rgb(248, 248, 248);
  color: black;
}

.hoverEffect:hover {
   color: #ffaea6; 
}