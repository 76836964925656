.pathContainer {
  position: relative;
  margin-bottom: 10px;
  margin-top: 5px;
  background-color: rgb(241, 241, 241);
  border-radius: 30px;
  padding-left: 20px;
  min-height: 20px;
  max-height: 90px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.pathContainer p {
  display: inline;
  color: #4d4d4d;
  font-size: 13px;
}

.pathContainer p:hover {
  cursor: pointer;
  color: #ff8b8b;
}
