.videoTutorialCloseButton {
  position: absolute;
  top: 3vw;
  left: 3vw;
  width: 4vw;
  height: 4vw;
  z-index: 9999;
}

.videoTutorialCloseButton:hover .videoTutorialCloseButtonIcon {
  cursor: pointer;
  color: rgb(254, 249, 249);
}

.videoTutorialCloseButtonIcon {
  color: #ff8b8b;
}

.learnMoreVideoButtonWrapper:hover {
  background-color: #ff8b8b;
}

.learnMoreVideoButtonWrapper:hover p {
  color: white;
  font-weight: 800;
}

.learnMoreVideoButtonWrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  padding: 10px 20px 10px 20px;
  border: 1px solid #ff8b8b;
  border-radius: 30px;
  color: #ff8b8b;
  cursor: pointer;
  transition: ease-in 0.1s;
}
