.simulationsContainer {
  position: relative;
  width: 100%;
  height: calc(100% - 40px);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  --sim-files-height: 22%;
  --wiki-container-height: 48%;
}
.circularButton {
  position: relative;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background-color: #fdfdfd;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  overflow: hidden;
  border: 1.2px solid #ffaea6;
}

.circularButton:hover {
  background-color: #ffaea6;
}

.circularButton:hover path {
  fill: white;
}

.disabledSimButtom {
  opacity: 0.7;
  cursor: not-allowed;
  pointer-events: none;
}

.simulationsEditNameDelete {
  position: relative;
  width: 100%;
  height: 28px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.simulationNameInputTop {
  height: 24px;
  border-radius: 25px;
  padding-left: 15px;
  outline: none;
  width: calc(100% - 57px);
  border: 1px solid #ffaea6;
  color: #ffaea6;
  background: transparent;
  appearance: none;
  cursor: text;
  font-size: 13px;
  text-align: left;
  transition: ease-in 0.1s;
  caret-color: #ffaea7;
}
.simulationNameInputTop:focus {
  border: 1.5px solid #ffaea6;
}

.simulationNameInputTop::placeholder {
  color: #ffaea6;
}

.simulationsContainer .rightButtonsWrapper {
  position: relative;
  top: 0px;
  width: 130px;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.simulationsContainer .EditWikiButtonWrapper {
  position: relative;
  display: flex;
  top: unset;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 25px;
  border: 1px solid #ffaea6;
  border-radius: 30px;
  color: #ffaea6;
  cursor: pointer;
  transition: ease-in 0.1s;
}

.largeGreyButtonWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  height: 20vh;
  margin-top: 2%;
}

.largeGreyButton {
  width: 100%;
  height: 100%;
  color: #7e7e7e;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: 1px solid lightgrey;
}

.largeGreyButton:hover {
  opacity: 0.9;
  border: 1px solid rgb(175, 175, 175);
  color: #6c6c6c;
  transition: cubic-bezier(0.075, 0.82, 0.165, 1);
  background-color: #fbfbfb;
  cursor: pointer;
}

.simulationsDropdown {
  position: relative;
  width: calc(100% - 37px) !important;
}

.controls {
  position: relative;
  width: 100%;
  height: 20%;
  background-color: transparent;
  max-height: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.simulationControlButton {
  margin-right: 5px;
}

.simulationBody {
  position: relative;
  width: 100%;
  min-height: calc(
    100% - 120px
  ); /* 120 is the height of the search bar + path + simulation dropdown*/
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.simulationsPlayerBar {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-left: 8px;
}

.bigGreyButton {
  width: 100%;
  height: 30%;
  color: #7e7e7e;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: 1px solid lightgrey;
}

.bigGreyButton:hover {
  opacity: 0.9;
  border: 1px solid rgb(175, 175, 175);
  color: #6c6c6c;
  transition: cubic-bezier(0.075, 0.82, 0.165, 1);
  background-color: #fbfbfb;
  cursor: pointer;
}

.SimStepsAndEditorResizeWrapper {
  position: absolute;
  top: calc(var(--sim-files-height) + 3px);
  height: 20px;
  width: 50%;
  cursor: row-resize;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  z-index: 9999;
}
.codeWikiResizeWrapper {
  position: absolute;
  top: calc(var(--wiki-container-height) + 2px);
  height: 20px;
  width: 50%;
  cursor: row-resize;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  z-index: 9999;
}
.verticalResizeHandle {
  position: relative;
  height: 2px;
  width: 20%;
  cursor: row-resize;
  background-color: #d4d4d4;
  border-radius: 3px;
}

.wikiAndCodeContainer {
  --offset: calc(22% + 25px);
  position: absolute;
  width: 100%;
  top: var(--offset);
  height: calc(100% - var(--offset));
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.wikiContainer {
  position: absolute;
  top: 0%;
  width: 100%;
  height: var(--wiki-container-height);
}

.wikiDiv {
  position: relative;
  height: 100%;
  border: 1px solid #ffaea6;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  overflow: hidden;
}
.wikiInner {
  position: relative;
  width: 95%;
  height: 94.6%;
  overflow: auto;
  margin-top: 10px;
}

.simulationCodeContainer {
  position: absolute;
  width: 100%;
  top: calc(48% + 20px);
  height: calc(var(--wiki-container-height) + 3%);
}

.codeDiv {
  position: relative;
  height: calc(100%);
  width: 100%;
  border: 1px solid #ffaea6;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: hidden;
}
/* 
.codeDiv .TabPanel {
  width: 97.4%;
  height: calc(100% - 10px);
  margin-top: 8px;
} */

.simulationCodeInnerContainer {
  margin-top: 8px;
  margin-left: 8px;
  position: relative;
  width: 95%;
  height: 98%;
  overflow: auto;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.SimStepsContainer {
  position: relative;
  height: var(--sim-files-height);
  width: calc(100% - 40px);
  overflow-y: hidden;
  overflow-x: hidden;
  display: flex;
}

.SimStepsList {
  position: relative;
  width: 100%;
  /* height: calc(100%); */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none;
  overflow-x: hidden;
  overflow-y: auto;
}

.SimStepsListItem {
  position: relative;
  width: 95%;
  height: 44px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: transform 0.2s ease;
}

.SimStepsListItem.dragging {
  z-index: 1000;
  transform: scale(1.2);
}

.simulationNode {
  position: relative;
  width: 100%;
  border-radius: 30px;
  margin: 5px 0;
  box-shadow: rgba(0, 0, 0, 0) 1.95px 1.95px 2.6px;
  display: flex;
  justify-content: space-between;
  height: 34px;
}

.simulationEdge {
  position: relative;
  left: 1px;
  width: 100%;
  margin: 0px 0 0px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  height: 30px;
  border-radius: 0px;
}
.simulationEdge:hover {
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px;
  cursor: pointer;
}

.simulationEdge:hover p,
.simulationEdge:hover .simulationNodeInternalText {
  color: #ffaea7;
}

.simulationEdge:hover svg {
  fill: #ffaea7;
}

.simulationEdge .simulationNodeInternalWrapper {
  padding-left: 0;
}

.selectedEdge p {
  color: #ffaea7;
}

.selectedEdge svg {
  fill: #ffaea7;
}

.simulationNodeInternalWrapper {
  width: 100%;
  height: 100%;
  padding: 7px 15px;
  cursor: pointer;
}

.simulationNodeInternalContent {
  position: relative;
  display: flex;
  align-items: center;
  margin-left: 5px;
  cursor: pointer;
}

.simulationNodeInternalText {
  font-family: "Poppins-Medium";
  font-size: 12px;
  color: #4d4d4d;
  margin: 0;
  vertical-align: middle;
}

.simStepLabelInput {
  position: relative;
  outline: none;
  width: 80%;
  background: transparent;
  appearance: none;
  cursor: text;
  text-align: left;
  transition: ease-in 0.1s;
  caret-color: #ffaea7;
}

.simulationNode:hover {
  box-shadow: rgba(0, 0, 0, 0.423) 1.95px 1.95px 2.6px;
  cursor: default;
}

.selectedNode {
  border: 1px solid #ffaea7;
}

.iconWrapper {
  position: relative;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: ease-in 0.1s;
  margin-right: 10px;
  color: rgba(104, 104, 104, 0.943);
  cursor: pointer;
}

.textEditorContainer {
  position: absolute;
  top: 8px;
  word-break: break-all;
  overflow: hidden;
  height: 95%;
  width: 98%;
}

.boxTitle {
  background-color: white;
  background: white;
  position: absolute;
  top: -17px;
  left: 20px;
  z-index: 1;
  padding: 0px 5px 0px 5px;
}

.textEditorContainer
  .ck-blurred.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline:not(
    .ck-read-only
  ) {
  position: absolute;
  top: 50px;
  height: 100%;
  width: calc(100% - 30px);
  overflow-y: scroll !important;
}
.textEditorContainer
  .ck-focused.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline {
  position: absolute;
  top: 50px;
  height: 100%;
  width: calc(100% - 30px);
  overflow-y: scroll !important;
}

.textEditorContainer .ck.ck-toolbar {
  position: absolute;
  top: 8px;
  width: 100%;
  border-radius: 0px !important;
  z-index: 9999 !important;
  border: 0px solid #ffaea6;
  border-bottom: 1px solid #ffaea6;
  padding-bottom: 5px;
}

.simulationNameInput {
  position: relative;
  border: none;
  outline: none;
  font-size: 3vh;
  width: 100%;
  padding: none;
  background: transparent;
  color: var(--deepestDarkestGrey);
  text-align: center;
  caret-color: #ffaea7;
}
.simulationNameInput::placeholder {
  color: var(--darkGrey);
}

.wikiIndicator {
  position: absolute;
  bottom: 10px;
  left: 15px;
  color: var(--grey);
  z-index: 9999;
}

.disabled {
  pointer-events: none;
  opacity: 0.4;
}
