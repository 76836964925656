.checkRunContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  position: relative;
  top: 1.85%;
}

.checkRunContainer::-webkit-scrollbar-thumb {
  background-color: #c7c7c7;
  border-radius: 2px;
  width: 2px;
}

.checkRunContainer::-webkit-scrollbar {
  background-color: #f4f4f4;
  border-radius: 2px;
  width: 2px;
}
