.text-editor {
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;
  height: 100%;
  width: 100%;
  --ck-focus-outer-shadow-geometry: 0 0 0 0;
  --ck-focus-outer-shadow: none;
  --ck-focus-disabled-outer-shadow: none;
  --ck-focus-error-outer-shadow: none;
  --ck-inner-shadow: none;
  --ck-drop-shadow: none;
  --ck-drop-shadow-active: none;
  --ck-color-shadow-drop: none;
  --ck-color-shadow-drop-active: none;
  --ck-color-focus-outer-shadow: none;
  --ck-color-shadow-inner: transparent;
  word-break: break-word;
}

/* mostly for code tab */
pre {
  font-size: 11px;
}

.ck-blurred.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline,
.ck-focused.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline {
  position: relative;
  margin-top: 2%;
  height: 90%;
  overflow-y: scroll;
}

.ck-focused.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline:focus {
  outline: 0;
}

.ck-focused.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline:focus {
  outline: 0;
}
.ck-focused {
  border: none !important;
  --ck-inner-shadow: none !important;
}
.ck
  ck-content
  ck-editor__editable
  ck-rounded-corners
  ck-editor__editable_inline
  ck-blurred:focus {
  outline: 0;
  --ck-color-shadow-inner: transparent;
}

.ck-toolbar {
  border-radius: 10px !important;
  z-index: 1000 !important;
}
