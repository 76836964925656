.inSimulationSnackBar {
  position: fixed;
  top: 0.7vh;
  left: 28vw;
  background-color: transparent;
}

.SnackBarWithToggle {
  position: fixed;
  top: 3.3vh;
  left: 27vw;
  background-color: transparent;
}

.snackBar {
  position: relative;
  top: 7vh;
  left: -35px;
  background-color: transparent;
  box-shadow: none;
  z-index: 99999;
}
