.search-bar img {
  position: absolute;
}

.search-bar-wrapper {
  display: flex;
  flex-direction: row;
}

.search-bar {
  width: 100%;
  margin-bottom: 10px;
  user-select: "none";
}

.search-icon {
  padding: 10px;
  width: 15px;
  text-align: center;
}

.search-input {
  width: -webkit-fill-available;
  padding: 5px;
  padding-left: 33px;
  border-radius: 30px;
  color: black;
  line-height: 1.5em;
  border: 1px solid var(--lightGrey);
  caret-color: var(--pinkerPink);
}

.search-input::placeholder {
  color: grey;
  font-weight: 500;
}

.search-input:focus {
  outline: none;
  border: 1px solid var(--lighterPink);
  &::placeholder {
    font-size: 12px;
    padding-left: 12px;
  }
}
