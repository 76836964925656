.chat-message {
  margin-bottom: 12px;
  padding: 8px;
  border-radius: 8px;
}

.chat-user {
  background-color: #f0f8ff; /* Light blue background for user messages */
  text-align: left;
  border: 1px solid #d0e0f0;
}

.chat-assistant {
  background-color: #f5f5f5; /* Light grey background for assistant messages */
  text-align: left;
  border: 1px solid #e0e0e0;
}

.chat-role {
  margin: 0;
  font-size: 1rem;
  font-weight: bold;
  color: #333; /* Neutral dark color */
}

.chat-content {
  margin: 4px 0 0 0;
  font-size: 0.9rem;
  color: #555; /* Slightly lighter color for content */
  line-height: 1.5;
}

.chatContainer {
  position: relative;
  height: 80%;
  overflow-y: auto;
  overflow-x: hidden;
  font-size: 11px;
  border: none;
  margin: 0;
  padding: 0;
  width: 100%;
}

.stickyTextBoxAI { 
  position: sticky;
  bottom: 0;
  z-index: 100;
  background-color: #ffffff;
  width: 100%;
  margin: 5px;
}