@import url("https://fonts.googleapis.com/css2?family=Neucha&display=swap");

.App {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transform: scale(0.97);
  transform-origin: top left;
  width: 103.1vw;
  height: 103.1vh;

  --lighterPink: #ffb6af;
  --pinkerPink: #ff8b8b;
  --grey: #d4d4d4;
  --darkGrey: #8f8f8f;
  --darkerGrey: #5d5d5d;
  --darkestGrey: #767676;
  --deepestDarkestGrey: #4d4d4d;
  --evenDarkerThanDeepestDarkestGrey: #272727;
  --lightGrey: #adadad;
  --lightestGrey: #faf8f8;
}

.TopControls {
  z-index: 2;
  position: absolute;
  top: 0;
}

.DrawIOFrame {
  position: absolute;
  height: 100%;
  width: 100%;
}

@media (max-width: 800px) {
  .heading {
    margin: 15px 0;
    font-size: 35px;
  }
}
