.Header {
  position: fixed;
  top: 10px;
  height: 8vh;
}

.NavBarButton {
  position: relative;
  color: white;
  font-weight: bold;
  cursor: pointer;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: ease-in 0.1s;
}

.Button {
  position: relative;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 40px;
  height: 26px;
  width: 60px;
  border: 1px solid #ffaea6;
  transition: ease-in 0.1s;
}

.Button:hover {
  background-color: #ffaea6;
}

.Button:hover p {
  color: white;
  font-weight: 700;
}

.NavBarButton:hover .Button {
  background-color: #ffaea6;
}

.NavBarButton:hover p {
  color: white;
  font-weight: 700;
}

.LogoWrapper {
  position: relative;
  height: 100%;
  top: 2vh;
  left: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.Logo {
  position: relative;
  width: 100%;
  height: 52%;
  color: #ffaea6;
  font-weight: 200;
  font-size: 33px;
  margin: 0;
  padding: 0;
  @media only screen and (max-width: 1800px) {
    /* This CSS will only apply when the viewport width is less than 1300px */
    padding-bottom: 2%;
  }
}

.LogoLink {
  margin-bottom: 5px;
}

.Subheader {
  position: relative;
  width: 100%;
}

.StayTunedHeader {
  position: relative;
  width: 100%;
}

.StayTunedPulse {
  animation: stayTunedPulsate 3s ease-out;
  animation-iteration-count: infinite;
  opacity: 0.65;
}

@keyframes stayTunedPulsate {
  0% {
    opacity: 0.65;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.65;
  }
}

.HelpIcon {
  width: "22px";
  cursor: pointer;
  margin-right: "15px";
}

.Header {
  position: fixed;
  background-color: transparent;
  height: 8vh;
  display: flex;
  left: 0;
  top: 0;
  width: 103.1vw;
  flex-direction: row;
  justify-content: space-between;
  z-index: 1;
}

.RightSectionOfHeader {
  display: flex;
  margin-right: 25px;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.DiscordButtonWrapper {
  position: relative;
  margin-right: 20px;
  width: 25px;
  height: 25px;
  &:hover .DiscordLogoPath {
    fill: #7289d9;
  }
}