.docsContainer {
  overflow-x: none;
  position: relative;
  height: 90%;
}

.docsControlContainer {
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  background-color: white;
  margin-top: 20px;
}

.docsTitleInput {
  border: none;
  outline: none;
  font-size: 3vh;
  width: auto;
  padding: none;
  background: transparent;
  color: black;
}

.text-editor-container {
  position: relative;
  word-break: break-all;
  height: 95%;
  margin-top: 2%;
  overflow-y: hidden;
}

.EditWikiButtonWrapper:hover {
  background-color: #ffaea6;
}

.EditWikiButtonWrapper:hover p {
  color: white;
  font-weight: 800;
}

.EditWikiButtonWrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 25px;
  border: 1px solid #ffaea6;
  border-radius: 30px;
  color: #ffaea6;
  cursor: pointer;
  transition: ease-in 0.1s;
}

.savelCancelButtonsWrapper {
  position: relative;
  width: 200px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

a {
  text-decoration: none;
  color: #ff8b8b;
  font-weight: bold;
}

a:hover {
  text-decoration: underline;
}

.docsTitleAndCommits {
  padding-left: 10px;
}
