.simulationDescriptionEditContainer {
  overflow-x: none;
  position: absolute;
  height: 85%;
  width: 100%;
  top: 0px;
  z-index: 9999;
}

.controlContainer {
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  background-color: white;
  margin-top: 20px;
}

.titleContainer {
  padding-left: 10px;
}

.docsTitleInput {
  border: none;
  outline: none;
  font-size: 3vh;
  width: auto;
  padding: none;
  background: transparent;
  color: black;
}

.text-editor-container {
  position: relative;
  word-break: break-all;
  height: 95%;
  margin-top: 2%;
}

.EditWikiButtonWrapper:hover {
  background-color: #ffaea6;
}

.EditWikiButtonWrapper:hover p {
  color: white;
  font-weight: 800;
}

.EditWikiButtonWrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 25px;
  border: 1px solid #ffaea6;
  border-radius: 30px;
  color: #ffaea6;
  cursor: pointer;
  transition: ease-in 0.1s;
}

.savelCancelButtonsWrapper {
  position: relative;
  width: 200px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

a {
  text-decoration: none;
  color: #ff8b8b;
  font-weight: bold;
}

a:hover {
  text-decoration: underline;
}

.largeGreyButton {
  width: 100%;
  height: 100%;
  color: #7e7e7e;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: 1px solid lightgrey;
  z-index: 9999;
}

.largeGreyButton:hover {
  opacity: 0.9;
  border: 1px solid rgb(175, 175, 175);
  color: #6c6c6c;
  transition: cubic-bezier(0.075, 0.82, 0.165, 1);
  background-color: #fbfbfb;
  cursor: pointer;
}

.simulationDescriptionPreviewContainer {
  --offset: calc(40% + 25px);
  position: absolute;
  width: 100%;
  top: var(--offset);
  height: calc(100% - var(--offset));
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.simulationDescriptionInnerContainer {
  position: absolute;
  top: 0%;
  width: 100%;
  height: 100%;
}
