.EmailDialogInput {
  border-radius: 25px;
  padding-left: 15px;
  outline: none;
  background: transparent;
  appearance: none;
  cursor: text;
  font-size: 1.4vw;
  text-align: left;
  transition: ease-in 0.1s;
  caret-color: #ffaea7;
  font-size: 18px;
  padding-top: 7px;
  padding-bottom: 7px;
  box-sizing: border-box;
}

.EmailDialogInput:invalid {
  border: 1.5px solid red;
}

.EmailDialogInput:valid {
  border: 1px solid #ffaea6;
}

.EmailDialogInput:focus {
  border: 1.5px solid #ffaea6;
}

.EmailDialogInput::placeholder {
  color: grey;
  opacity: 0.6;
}

.EmailDialogInputButtonWrapper {
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  max-width: 80%;
}