.CheckRunViewContainer {
  background-color: #ffffff;
  margin: auto 0;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  z-index: 998;
  overflow: hidden;
}

.openCheckRunView {
  opacity: 1;
  transition-duration: 0.2s;
}

.CheckRunTitleWrapper {
  position: relative;
  margin-bottom: 20px;
  margin-top: 15px;
  background-color: rgb(241, 241, 241);
  border-radius: 30px;
  padding-left: 20px;
  min-height: 20px;
  max-height: 90px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.CheckRunTitle {
  display: inline;
  color: #4d4d4d;
  font-size: 13px;
  margin-right: 4px;
  user-select: none;
}

.TabPanel {
  height: 100%;
}

.CheckRunViewContentContainer {
  position: relative;
  margin-top: 3%;
  height: 87%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.CheckRunViewContent {
  position: relative;
  width: 90%;
  height: 70%;
}

.MuiTabs-indicator {
  transform: translateX(
    11%
  ) !important; /* To fix the indicator position after scaling the app down */
}

.CheckRunButtonsContainer {
  position: relative;
  width: 55%;
  margin-top: 55px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.CancelButton {
  width: 43%;
}

.disabled {
  pointer-events: none;
  opacity: 0.7;
}

.CompleteReviewButtonWrapper {
  pointer-events: auto;
  width: 43%;
}

.CompleteReviewButton {
  width: 100%;
}
