.HeaderDropdownWrapper {
  width: 260px;
  padding-right: 15px;
  color: white;
}

.HeaderDropdown {
  border-radius: 40px;
  padding-left: 15px;
  padding-right: 15px;
  outline: none;
  height: 28px;
  border: 1px solid #ffaea6;
  color: #ffaea6;
  background: transparent;
  appearance: none;
  cursor: pointer;
  font-size: 13px;
}

.HeaderDropdownDisabled {
  cursor: not-allowed;
}

.HeaderDropdownHighlight {
  background: #ffaea6;
  color: white;
  font-weight: bold;
  box-shadow: 0 0 0 0 rgb(255, 174, 166);
  transform: scale(1);
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 174, 166, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 174, 166, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 174, 166, 0);
  }
}
