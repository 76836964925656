.DialogButton {
  width: 25%;
  height: 32px;
}

.DialogButtonExtended {
  width: 65%;
}

.FullDialogButton {
  width: 100%;
  margin:1%;
}

.HalfWidthDialogButton {
  width: 10vw;
}

.DialogButtonGroupHorizontal {
  position: relative;
  width: 100%;
  height: 80px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.DialogButtonGroupVertical {
  position: relative;
  width: 100%;
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 5% 0;
}

.DialogContent {
  width: 80%;
}
