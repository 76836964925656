.landing-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: rgb(0 0 0 / 56%);
  z-index: 999;
  padding-bottom: 100px;
}

.landing-logo {
  position: absolute;
  left: 40px;
  top: 20px;
  transform: translate(-50%, -50%);
  z-index: -1;
  width: 600px;
}

.landing-title h1 {
  font-family: Poppins-Thin;
  color: #ffaea6;
  font-size: 40px;
  margin: 0;
  letter-spacing: 1px;
}

.landing-title {
  background-color: white;
  border-radius: 100px;
  margin-bottom: 20px;
  padding: 25px 45px;
  box-shadow: 0px 4px 4px 0px #00000040;
}

.landing-content {
  background: white;
  min-width: 800px;
  padding: 50px 70px 20px 70px;
  border-radius: 45px;
  text-align: center;
  position: relative;
  box-shadow: 0px 4px 4px 0px #00000040;
}

.landing-content-ctas {
  display: flex;
  margin: 0 20px;
  margin-bottom: 15px;
  justify-content: center;
  align-items: center;
}

.landing-content-main {
  /* border-right: solid 1px #DEDEDE; */
  padding-right: 60px;
  margin-right: 60px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
}

/* Pseudo element to create the short dividing line  */
.landing-content-main:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  width: 1px;
  height: 83%;
  border-right: solid 1px #dedede;
}

.landing-content-info {
  display: flex;
  flex-direction: column;
  width: 20%;
}

.landing-button {
  /* Lazy way to undo all button default styling */
  all: unset;
  outline: revert;
  padding: 8px 35px;
  border: solid 1px #ffaea78e;
  border-radius: 20px;
  margin: 23px 0;
  text-align: center;
  white-space: nowrap;
  position: relative;
  font-weight: 600;
  font-size: 15px;
  font-family: Poppins-Medium;
}

.landing-button .MuiTypography-root {
  font-weight: 600;
  font-size: 15px;
  font-family: Poppins-Medium;
}

.landing-button:hover {
  background: #ffaea6;
  cursor: pointer;
  color: white;
}

.landing-button:hover p {
  color: white;
}

.landing-url-wrapper {
  position: relative;
}

.landing-button-main-icon-wrapper,
.landing-button-info-icon-wrapper {
  left: 0;
  top: 50%;
  transform: translate(-50%, -50%);
  outline: solid 1px #ffaea7;
  border: solid 5px white;
  box-sizing: border-box;
  background: rgb(254, 243, 243);
  color: #ffaea7;
  border-radius: 100%;
  position: absolute;
  width: 60px;
  aspect-ratio: 1/1;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.landing-button-main-icon-wrapper .MuiSvgIcon-root,
.landing-button-info-icon-wrapper .MuiSvgIcon-root {
  width: 60%;
  height: auto;
}

.landing-button-main-icon-wrapper.landing-button-icon-github {
  background: white;
  overflow: hidden;
}

.landing-button-main-icon-wrapper.landing-button-icon-github .MuiSvgIcon-root {
  width: 110%;
}

.landing-url-input {
  width: 100%;
  caret-color: #ffaea7;
  color: #ffaea7;
  box-sizing: border-box;
}

.landing-button-info-icon-wrapper {
  left: 15px;
  top: -10px;
  transform: translate(0, -50%);
  border: solid 3px white;
  width: 40px;
}

.landing-button-playicon.MuiSvgIcon-root {
  width: 110%;
  height: auto;
}

.landing-url-input:focus-visible {
  outline: solid 2.5px #ffaea7;
}

.landing-url-input::placeholder {
  color: #ffaea7;
}

.landing-url-input:hover {
  background: white;
  cursor: text;
  color: #ffaea7;
}

.landing-button-playicon {
  animation: playLogoFade 1s infinite alternate;
}

/* Keyframes for text opacity animation */
@keyframes playLogoFade {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
  .demoVideoButtonLabel {
    top: 20.9vw;
  }
}

.landingComponentCloseButton {
  position: absolute;
  top: -130px;
  right: 10px;
  width: 60px;
  z-index: 9999;
}

.landingComponentCloseButtonIcon.MuiSvgIcon-root {
  color: #ff8b8b;
  width: 100%;
  height: auto;
}

.landingComponentCloseButton:hover .landingComponentCloseButtonIcon {
  cursor: pointer;
  color: white;
}
