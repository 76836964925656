body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: none;
}

:root { 
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
  display: none;
}
::-webkit-scrollbar {
  background-color: #f4f4f4;
  border-radius: 2px;
  width: 2px;
}
::-webkit-scrollbar-thumb {
  background-color: #c7c7c7;
  border-radius: 2px;
  width: 2px;
}

::-webkit-scrollbar:horizontal {
  background-color: #f4f4f4;
  border-radius: 2px;
  height: 2px;
}

::-webkit-scrollbar-thumb:horizontal{
  background-color: #c7c7c7;
  border-radius: 2px;
  height: 2px;
}

@font-face {
  font-family: "Poppins-Light";
  font-display: swap;
  src: local("Poppins-Light"), url(./Fonts/Poppins-Light.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins-Medium";
  font-display: swap;

  src: local("Poppins-Medium"),
    url(./Fonts/Poppins-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins-Thin";
  font-display: swap;

  src: local("Poppins-Thin"), url(./Fonts/Poppins-Thin.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins-ExtraLight";
  font-display: swap;

  src: local("Poppins-ExtraLight"),
    url(./Fonts/Poppins-ExtraLight.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins-ExtraBold";
  font-display: swap;

  src: local("Poppins-ExtraBold"),
    url(./Fonts/Poppins-ExtraBold.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins-Bold";
  font-display: swap;

  src: local("Poppins-Bold"), url(./Fonts/Poppins-Bold.ttf) format("truetype");
}
